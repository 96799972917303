html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Plump', sans-serif; /* Use the custom font */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}