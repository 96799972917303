// .container {
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: center;
//   margin-top: 40px;
//   height: 400px;
//   gap: 1rem;
//   width: 400px;
// }

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #012321;
  width: 300px;
  border-radius: 15px;
  color: #fff;
  overflow: hidden; 
  height: 350px;
  gap: 2rem;
  margin-top: 20px; 
}
.container::after {
  content: '';
  position: absolute;
  top: 5px; /* Spacing from the top edge */
  bottom: 5px; /* Spacing from the bottom edge */
  left: 5px; /* Spacing from the left edge */
  right: 5px; /* Spacing from the right edge */
  border: 3px solid #F9F6EE; /* The inner border color and width */
  pointer-events: none; /* Prevent the pseudo-element from capturing mouse events */
  border-radius: 12px; /* Slightly less than the container's border-radius to maintain the curved effect */
}




.img img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #F9F6EE;
}

.name {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}

.description {
  font-size: 17px;

  text-align: center;
  width: 250px;
}
