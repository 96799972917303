
.about {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;
  color: black;
  text-align: center;
  @media (max-width: 375px) {
    padding: 0 20px;
  }
}

.about span {
  font-style: italic;
}

.about h1 {
  font-size: 40px;
  color: #26ef8e;
  align-self: center;
  @media (max-width: 768px) {
    font-size: 30px;
  }
}

.about p {
  font-size: 16px;
  max-width: 400px;
  line-height: 1.7;
}
