.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.header h1 {
  font-size: 46px;
  color: #26ef8e;
  position: relative; /* Needed for absolute positioning of the pseudo-element */
  display: inline-block; /* Ensures the container fits the content */
  margin: 0;
  /* Adjust space between text and underline */
}

.header h1::after {
  content: ""; /* Required for pseudo-elements */
  display: block; /* Treat the pseudo-element like a block for sizing */
  width: 70%; /* Underline width relative to the h1 element */
  height: 2px; /* Thickness of the underline */
  background-color: currentColor; /* Matches the color of the text */
  position: absolute;
  left: 15%; /* Centers the underline (100% - 25%) / 2 = 37.5% */
  bottom: 0; /* Position at the bottom of the h1 element */
}

.partnerContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

@media (max-width: 1000px) {
  .partnerContainer {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }
  .header {
    padding: 20px;
  }
}


