:root {
  --navbar-height: 80px;
  --navbar-background-color: #ffffff;
  --navbar-text-color: #26ef8e;
  --navbar-hover-color: #26ef8e;
  --navbar-font-size: 1rem;
  --navbar-item-spacing: 2rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--navbar-height);
  background-color: var(--navbar-background-color);
  color: var(--navbar-text-color);
  padding: 0 10%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  flex: 0 0 220px;
  margin-right: auto;
}

.image {
  width: 100%;
  height: auto;
}

.logo img {
  width: 80px;
  height: 80px;
}

.navbarGroup {
  display: flex;
  gap: var(--navbar-item-spacing);
  transition: all 0.3s ease-in-out;
}

.navbarItem a {
  text-decoration: none;
  color: var(--navbar-text-color);
  font-size: 20px;
  padding-bottom: 0.5rem;
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
}

.navbarItem a::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background-color: var(--navbar-hover-color);
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.5s ease, left 0.5s ease;
  transform: translateX(0);
}

.logo {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.logoText a {
  font-size: 35px;
}

.navbarItem a:hover::after {
  width: 100%;
  left: 0;
}

.navbarItem a.active::after {
  width: 100%;
  left: 0;
}
.burger {
  display: none;
}

.hidden {
  display: none;
}

@media (max-width: 920px) {
  .navbarGroup {
    display: none;
    flex-direction: column;
    padding: 1rem;
    position: absolute;
    top: var(--navbar-height);
    right: 0;
    background-color: var(--navbar-background-color);
    width: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100; /* Ensure it's on top of other elements */
  }

  .navbarGroup.visible {
    display: flex;
  }

  .navbarItem {
    margin: 0.5rem 0;
    width: 100%;
    text-align: end; /* Full width for each navbar item */
  }

  /* Styles for the burger menu icon */
  .burger {
    display: block;
    cursor: pointer;
    font-size: 30px;
    position: absolute;
    top: 20px; /* Adjust as needed for your design */
    right: 20px; /* Adjust as needed for your design */
    z-index: 101; /* Ensure burger is above the navbarGroup */
  }
}

@media (max-width: 340px) {
  .logoText {
    display: none;
  }
}
