.socials {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px;
    gap: 3rem;
}

.x {
    max-width: 50px;
    max-height: 50px;
}