.main {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    position: relative; /* Set position to relative */
  }

  .pepe {

    @media (max-width: 1000px){
      max-width: 425px;
    }

    @media (max-width: 425px) {
      max-width: 300px;
    }
  }