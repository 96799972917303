.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.cards {
  padding-top: 40px;
  padding-bottom: 100px;
  width: 100%;
  background-color: #3dac78;
}

.wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 7rem;
  background-color: #fff;
  margin-top: 40px;
}

@media (max-width: 980px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
  }
}
