.container {
  width: 100%;
  height: 600px;
  background: rgb(1, 35, 33);
  background: rgb(1, 35, 33);
  background: rgb(1, 35, 33);
  background: rgb(1, 35, 33);
  background: rgb(1, 35, 33);
  background: linear-gradient(
    90deg,
    rgba(1, 35, 33, 1) 0%,
    rgba(0, 131, 82, 1) 92%
  );

  padding: 0 40px; 
  display: flex;
}


.text {
  color: #ffffef;
  line-height: 1.7;
  width: 450px; 
  margin-top: 20px;
  text-shadow: 2px 2px black;
}

.text p {
  margin-bottom: 50px;
}
.img {
  margin-left: auto; 
}

@media (max-width: 1000px) {
  .img {
    display: none;
  }
}
