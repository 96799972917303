.listContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: flex-start;
  margin: 20px;
  padding: 20px;
}

@media (max-width: 980px) {
  .listContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
